<template>
  <section class="tooltip" :class="[autoPosition, { focussed: focussed }]" @mouseover="setHovered(true)"
    @mouseleave="setHovered(false)" @click.stop="focusTooltip" @blur="blurTooltip" tabindex="0"
    @keypress.enter="focusTooltip">
    <div class="toggle" ref="toggle">
      <slot name="toggle">
        <icon :icon="icon" size="s" />
      </slot>
    </div>
    <transition name="show-tooltip" @enter="calculateOffset" @after-leave="resetOffset">
      <div class="tooltip-bubble" v-show="visible">
        <div ref="tooltipRef" class="tooltip-content-container" :style="offsetVariable">
          <section class="tooltip-content">
            <slot name="text">
              <span class="text"> {{ text }} </span>
            </slot>
          </section>
        </div>
        <span class="pointer"></span>
      </div>
    </transition>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import Icon from '../icon/Icon.vue';

const props = defineProps({
  icon: {
    type: String,
    default: 'info-circle',
  },
  text: String,
  closeToggle: {
    type: Boolean,
    default: true,
  },
  position: {
    required: false,
    type: String,
    default: 'top-right',
    validator: (value) =>
      [
        'left',
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ].includes(value),
  },
});

/* Ref of the tooltip content for positioning */
const tooltipRef = ref(null);

/* Keep track of hovered and focussed state, if lost focus, force close */
const hovered = ref(false);
const focussed = ref(false);

const setHovered = (value) => {
  if (hovered.value !== value) hovered.value = value;
};

const focusTooltip = () => {
  if (focussed.value) {
    focussed.value = false;
    hovered.value = false;
  }
  else {
    focussed.value = true;
  }
};

const blurTooltip = () => {
  focussed.value = false;
};

const visible = computed(() => hovered.value || focussed.value);

/*  Functions to open/close the tooltip from it's parent component */

const manualClose = () => {
  focussed.value = false;
  hovered.value = false;
};

const manualOpen = () => {
  focussed.value = true;
};

defineExpose({
  manualClose,
  manualOpen,
});


/* Auto position the tooltip in case it goes off screen */
const autoPosition = ref(props.position)

/* Offsetting the tooltip content. default is 32px */
const offset = ref(32)

const offsetVariable = computed(() => {
  return { '--offset': `${Math.ceil(offset.value)}px` };
});

const calculateOffset = () => {
  if (!tooltipRef.value) {
    return;
  }
  const rect = tooltipRef.value.getBoundingClientRect();

  const positionLeft = rect.left;
  const positionRight = window.innerWidth - rect.right;

  const positionTop = rect.top;
  const positionBottom = window.innerHeight - rect.bottom;

  // If tooltip is set to Left, check if it goes off screen, if so, change the position
  if (props.position === 'left') {
    if (positionLeft < 0) {
      autoPosition.value = 'bottom-left';
    }
    if (positionRight < 0) {
      autoPosition.value = 'bottom-right';
    }
  }

  // Check if the tooltip goes off screen at the top
  if (positionTop < 0) {
    autoPosition.value = props.position === 'top-right' ? 'bottom-right' : 'bottom-left';
  }

  // Check if the tooltip goes off screen at the bottom
  if (positionBottom < 0) {
    autoPosition.value = props.position === 'bottom-right' ? 'top-right' : 'top-left';
  }

  // Modal is off screen to left, change the offset based on the position
  if (rect.left < 0) {
    offset.value = (rect.left + 48) * (autoPosition.value.includes('right') ? -1 : 1);
  }

  // Modal is off screen to right
  if (window.innerWidth - rect.right < 0) {
    offset.value = (window.innerWidth - rect.right - 48) * (autoPosition.value.includes('right') ? -1 : 1);
  }
};

// Reset to base position
const resetOffset = () => {
  offset.value = 32;
  autoPosition.value = props.position;
};

</script>

<style lang="scss" scoped>
@use 'assets/scss/all' as *;
@use './tooltip';

.tooltip {
  @include tooltip.manual-trigger();

  border-radius: 15px;

  &.focussed {
    outline: 4px double $all-blue-20;
  }

  &.top-right {
    @include tooltip.top-right();
  }

  &.top-left {
    @include tooltip.top-left();
  }

  &.left {
    @include tooltip.left();
  }

  &.bottom-right {
    @include tooltip.bottom-right();
  }

  &.bottom-left {
    @include tooltip.bottom-left();
  }

  .show-tooltip-enter-active,
  .show-tooltip-leave-active {
    transition-property: opacity;
    transition-duration: 0.18s;
    transition-timing-function: ease-out;
    z-index: 10;

  }

  .show-tooltip-enter-from,
  .show-tooltip-leave-to {
    opacity: 0;
  }

}
</style>
