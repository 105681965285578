<script setup>
</script>

<template>
  <transition-group name="comparer-products">
    <slot></slot>
  </transition-group>
</template>

<style lang="scss">
.comparer-products-move {
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transition-duration: .65s;
}

.comparer-products-enter-active {
  transition-timing-function: cubic-bezier(0.59, 0.46, 0.46, 1.1);
  transition-duration: .5s;
  transition-property: transform, opacity;
}

.comparer-products-leave-active {
  transition-timing-function: ease-in;
  transition-duration: 0.26s;
  transition-property: transform, opacity;
  transition-delay: 0s !important;
}

.comparer-products-enter-active {
  &:nth-child(n + 6) {
    transition-delay: 1s;
  }

  &:nth-child(1),
  &:nth-child(11) {
    transition-delay: 0.15s;
  }

  &:nth-child(2),
  &:nth-child(12) {
    transition-delay: 0.3s;
  }

  &:nth-child(3),
  &:nth-child(13) {
    transition-delay: 0.45s;
  }

  &:nth-child(4),
  &:nth-child(14) {
    transition-delay: 0.6s;
  }

  &:nth-child(5),
  &:nth-child(15) {
    transition-delay: 0.75s;
  }
}



.comparer-products-enter,
.comparer-products-enter-from {
  opacity: 0;
  transform: translateX(100%) rotate(6deg) scale(0.6);
}

.comparer-products-enter-to {
  opacity: 1;
  transform: none;
}

.comparer-products-leave-to {
  opacity: 0;
  transform: translateX(-16px);
}
</style>
